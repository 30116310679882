import React, { useState, useEffect, useContext, createContext } from "react"
import axios from "axios"
import IconEvent from "../assets/svg/event.jsx"

const CalendarContext = createContext()

const Calendar = () => {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const [currentDay, setCurrentDay] = useState(new Date())
    const [data, setData] = useState([])
    const [dayPopup, setDayPopup] = useState(false)

    useEffect(() => {
        const getTimes = () => {
            axios.get(
                process.env.GATSBY_CALENDAR_ENDPOINT,
                { params: { month: currentDay.getMonth() + 1, year: currentDay.getFullYear() } }
            ).then(function (response) {
                setData(response.data)
            }).catch(function (error) {
                console.log(error)
            })
        }
        getTimes()
    }, [currentDay])

    const nextMonth = () => {
        const _nextMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 1)
        if (_nextMonth.getFullYear() > new Date().getFullYear()) {
            return
        }
        setCurrentDay(_nextMonth)
    }

    const previousMonth = () => {
        const _prevMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() - 1, 1)
        if (_prevMonth.getMonth() < new Date().getMonth()) {
            return
        }
        setCurrentDay(_prevMonth)
    }

    const isPrevMonthDisabled = () => {
        const currentMonth = new Date().getMonth()
        if (currentMonth === currentDay.getMonth()) {
            return false
        }
        return true
    }

    const isNextMonthDisabled = () => {
        return (currentDay.getMonth() !== 11)
    }

    return (
        <CalendarContext.Provider value={{ data }}>
            <div className="calendar">
                <div className="calendar-header d-flex justify-content-between py-3">
                    <button
                        className="btn btn-primary"
                        onClick={previousMonth}
                        disabled={!isPrevMonthDisabled()}
                    >&lt;</button>
                    <h2>{months[currentDay.getMonth()]} {currentDay.getFullYear()}</h2>
                    <button
                        className="btn btn-primary"
                        onClick={nextMonth}
                        disabled={!isNextMonthDisabled()}
                    >&gt;</button>
                </div>
                <div className="calendar-body">
                    <div className="table-header mb-3 lg-mb-0 bg-primary text-white" key="calender-header">
                        {weekdays.map((weekday) => {
                            return <div className="cell weekday py-2" key={weekday}>{weekday}</div>
                        })}
                    </div>

                    <Days day={currentDay} openPopup={(dateKey) => setDayPopup(dateKey)} />
                </div>

                <div className="pt-3 d-flex justify-content-center key-wrapper">
                    <div className="d-flex me-2 key-inner"><span className="key d-block me-2" style={{ width: "30px", height: "20px", backgroundColor: "#f8d348" }}></span><span>Standard Day</span></div>
                    <div className="d-flex me-2 key-inner"><span className="key d-block me-2" style={{ width: "30px", height: "20px", backgroundColor: "#dd6c30" }}></span>Event Day</div>
                </div>
            </div>

            {dayPopup !== false &&
                <CalendarDayPopup day={currentDay} dateKey={dayPopup} closePopup={() => setDayPopup(false)} />
            }

        </CalendarContext.Provider>
    )
}

const Days = (props) => {
    const { data } = useContext(CalendarContext)

    let currentDays = getDayData(props.day, data)

    const openPopup = (dateKey) => {
        props.openPopup(dateKey)
        document.body.style.overflow = 'hidden'
    }

    return (
        <div className="table-content" key={props.day}>
            {currentDays.map((day, key) => {
                let dataData = day.data
                let period = ''

                if (dataData) {
                    if (dataData.period != null) {
                        period = dataData.period.toLowerCase()
                    }
                }

                return (
                    <div className={`cell calendar-day ${day.currentMonth ? 'current' : ''} ${(dataData ? period : '')}`} onClick={() => { dataData && openPopup(key) }} key={key}>
                        <span className="text-day">{day.number}</span>
                        {dataData &&
                            <span className="opening-times">{dataData.openFrom} - {dataData.openTo}</span>
                        }

                        {(dataData && dataData.eventOneName) &&
                            <div className="pb-0 event-name">{dataData.eventOneName}</div>
                        }

                        {(dataData && dataData.eventTwoName) &&
                            <div className="pb-0 event-name">{dataData.eventTwoName}</div>
                        }

                        {(dataData && dataData.eventThreeName) &&
                            <div className="pb-0 event-name">{dataData.eventThreeName}</div>
                        }

                        {dataData &&
                            ((dataData.eventOneName || dataData.eventTwoName || dataData.eventThreeName) &&
                                <span className="event-icon">
                                    <IconEvent />
                                </span>
                            )
                        }
                    </div>
                )
            })}
        </div>
    )
}

const CalendarDayPopup = (props) => {
    const { data } = useContext(CalendarContext)
    let currentDays = getDayData(props.day, data)
    let dayData = currentDays[props.dateKey]
    let dayDataChild = dayData.data

    const closePopup = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            props.closePopup()
            document.body.style.overflow = 'auto'
        }
    }

    const popupRef = React.createRef()

    return (
        <div className="pop-up-overlay" role="dialog" aria-modal="true" data-headlessui-state="open" onClick={(e) => closePopup(e)}>
            <div className="pop-up-container bg-primary">
                <div className="pop-up-header">
                    <a className="pop-up-close" onClick={(e) => closePopup(e)}>x</a>
                </div>
                <div className="pop-up-content text-white h-100" ref={popupRef}>
                    <div className="row p-4 h-100">
                        <div className="h-100 d-flex flex-column justify-content-between">
                            <div>
                                <h4>{formatDate(dayData.fullDay)}</h4>
                                <span>We are open today {dayDataChild.openFrom} - {dayDataChild.openTo} (Last admission {dayDataChild.lastAdmission})</span>
                                <div className="mt-1">
                                    <hr />
                                    <h3 className="text-white">Admission Prices</h3>
                                    <p className="mb-0">Under 1 meter: Free</p>
                                    <p className="mb-0">Standard Entry:
                                        {((dayDataChild.adultPrice === dayDataChild.adultGatePrice) || (!dayDataChild.adultGatePrice)) ?
                                            <> &pound;{addDecimalPlaces(dayDataChild.adultPrice)}</> :
                                            <> Online &pound;{addDecimalPlaces(dayDataChild.adultPrice)} - Gate &pound;{addDecimalPlaces(dayDataChild.adultGatePrice)}</>
                                        }
                                    </p>
                                    <p className="mb-0">Concession: &pound;{addDecimalPlaces(dayDataChild.conPrice)}</p>
                                </div>
                                {(dayDataChild.eventOneName || dayDataChild.eventTwoName || dayDataChild.eventThreeName) &&
                                    <div className="mt-1">
                                        <hr />
                                        <h3 className="text-white">What's on</h3>

                                        <div className="ps-2">
                                            {dayDataChild.eventOneName &&
                                                <div className="pb-1"><a href={dayDataChild.eventOneLink ? dayDataChild.eventOneLink : '#'} target="_blank">{dayDataChild.eventOneName}</a></div>
                                            }

                                            {dayDataChild.eventTwoName &&
                                                <div className="pb-1"><a href={dayDataChild.eventTwoLink ? dayDataChild.eventTwoLink : '#'} target="_blank">{dayDataChild.eventTwoName}</a></div>
                                            }

                                            {dayDataChild.eventThreeName &&
                                                <div className="pb-1"><a href={dayDataChild.eventThreeLink ? dayDataChild.eventThreeLink : '#'} target="_blank">{dayDataChild.eventThreeName}</a></div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <a href="https://blackgangchine.digitickets.co.uk/tickets" target="_blank" className="btn btn-secondary text-primary mt-3 mb-0 fw-bold rounded">
                                Buy Tickets Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const getDayData = (_day, _data) => {

    const firstDayOfMonth = new Date(_day.getFullYear(), _day.getMonth(), 1)
    const weekdayOfFirstDay = firstDayOfMonth.getDay()
    let currentDays = []

    for (let day = 0; day < 42; day++) {
        if (day === 0 && weekdayOfFirstDay === 0) {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7)
        } else if (day === 0) {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (day - weekdayOfFirstDay))
        } else {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1)
        }

        const dayStr = (firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0') + "-" + firstDayOfMonth.getDate().toString().padStart(2, '0') + "-" + firstDayOfMonth.getFullYear()

        //switch

        let calendarDay = {
            currentMonth: (firstDayOfMonth.getMonth() === _day.getMonth()),
            date: (new Date(firstDayOfMonth)),
            month: firstDayOfMonth.getMonth(),
            number: firstDayOfMonth.getDate(),
            selected: (firstDayOfMonth.toDateString() === _day.toDateString()),
            year: firstDayOfMonth.getFullYear(),
            data: (dayStr in _data) ? _data[dayStr] : null,
            fullDay: dayStr
        }

        currentDays.push(calendarDay)
    }

    return currentDays
}

const formatDate = (inputDate) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]

    const days = [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ]

    const dateParts = inputDate.split('-')
    const [month, day, year] = dateParts.map(part => parseInt(part, 10))

    const formattedDate = new Date(year, month - 1, day)

    const dayName = days[formattedDate.getDay()]
    const monthName = months[formattedDate.getMonth()]
    let suffix = ''

    const lastDigit = dateParts[1] % 10

    switch (lastDigit) {
        case 1:
            suffix = 'st'
            break
        case 2:
            suffix = 'nd'
            break
        case 3:
            suffix = 'rd'
            break
        default:
            suffix = 'th'
            break
    }

    return `${dayName}, ${dateParts[1]}${suffix} ${monthName}`
}

const addDecimalPlaces = (number) => {
    const formattedTime = parseFloat(number).toFixed(2)
    return formattedTime
}

export default Calendar
