import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Title from "../../components/title"
import Calendar from "../../components/calendar"

export const query = graphql`
  query TEMPLATE_WP_OPENING_PAGE_QUERY(
    $id: String!
  ) {
    wpPage(id: { eq: $id }) {
      ...pageData
      ...gallery
      ...pageVariables
    }
  }
`

const Page = ({ data = {} }) => {
  const { wpPage: page = {} } = data
  const {
    pageVariables = {},
    pageMeta = {},
    smartcrawlSeo = {}
  } = page

  const images = (page.imageGallery.gallery != null) ? page.imageGallery.gallery.map(({ localFile }) => localFile.childImageSharp) : null
  const { title: title = '', metaDesc = '' } = smartcrawlSeo

  const image = pageMeta.headerImage && convertToBgImage(getImage(page.pageMeta.headerImage.localFile))

  return (
    <React.Fragment>
      <Seo post={data.wpPage} />
      {pageVariables &&
        <style dangerouslySetInnerHTML={{
          __html: `
        :root {
          ${(pageVariables.primaryColour ? '--bs-primary:' + pageVariables.primaryColour + ';' : '')}
        }

        main { 
          background-color: ${(pageVariables.pageBackground ? 'var(--bs-' + pageVariables.pageBackground + ')' : '#fff')};
          color: ${(pageVariables.pageBackground == 'dark' ? 'var(--bs-light)' : 'var(--bs-body-color)')};
        }
        `}} />
      }
      <main>
        {image &&
          <BackgroundImage
            {...image}
            className="header-image"
          />
        }

        <Title title={page.title} />

        <div className="container py-5">
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>

        <div className="container py-5">
          <Calendar />
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page